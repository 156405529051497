<script setup>
import { computed, onMounted } from 'vue';
import { useRolesStore } from '~/stores/account/roles.store';

const store = useRolesStore();
const props = defineProps(['role_key']);

onMounted(async () => {
    if (!roles.value.length) {
        await store.fetchRoles();
    }
});

const roles = computed(() => store.rolesGetter());

const hasRole = computed(() => !!roles.value.find(item => item.key == props.role_key));
</script>

<template>
    <template v-if="roles.value?.length === 0">
        <span> Permission denied </span>
    </template>

    <template v-if="hasRole">
        <slot></slot>
    </template>
</template>